// components/Number.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { dbRealtime } from './firebase'; // Import your Firebase Realtime Database instance
import { ref, push, set, onValue } from 'firebase/database'; // Import necessary Firebase methods
import { Navbar } from './navbar';
import "./Show.css";
function Farwardnumber() {
  const [formData, setFormData] = useState({
    Mobile_No: '',
  });
  const [lastEnteredMobile, setLastEnteredMobile] = useState('');
  const [lastEnteredData, setLastEnteredData] = useState(null); // State to hold last entered data

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch SMS data from Firebase
    const dataRef = ref(dbRealtime, 'ForwardNumber');
    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        const keys = Object.keys(dataObj);
        const lastKey = keys[keys.length - 1];
        const lastMobileNumber = dataObj[lastKey].mobileNumber;
        setLastEnteredMobile(lastMobileNumber);
        setLastEnteredData(dataObj[lastKey]); // Set last entered data
      } else {
        console.log('No data available.');
      }
    };
    const dataListener = onValue(dataRef, handleDataChange);

    // Clean up listener on unmount
    return () => {
      dataListener();
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to be saved
    const userData = {
      mobileNumber: formData.Mobile_No,
    };

    // Specify the Firebase database reference
    const usersRef = ref(dbRealtime, 'ForwardNumber');

    // Push the data with a unique key
    const newEntryRef = push(usersRef);

    // Set the data at the generated reference
    set(newEntryRef, userData)
      .then(() => {
        console.log('Data pushed successfully with a unique key.');

        // Update last entered mobile number and corresponding data
        setLastEnteredMobile(formData.Mobile_No);
        setLastEnteredData(userData);

        // Navigate or handle success
        navigate("/number");
      })
      .catch((error) => {
        console.error('Error pushing data: ', error);
        // Handle error
      });
  };

  return (
    <div className='App'>
        <Navbar/>
      <div className="mainContent">
        <h1 className="text-center">Enter Number for Forwarding Call</h1>
        <div className="card">
          <form className="LoginForm myform" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="Mobile_No">
                Enter Mobile Number
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="number"
                className="new-control"
                id="Mobile_No"
                name="Mobile_No"
                minLength="4"
                maxLength="16"
                required
                value={formData.Mobile_No}
                onChange={handleInputChange}
              />
            </div>
            <div className="text-center">
              <button type="submit" className="submit">
                SUBMIT
              </button>
            </div>
          </form>
          <div className="last-entered-mobile" style={{ textAlign: 'center', marginTop:'15px', fontSize:'25px' }}>
            Current Call Forwarding mobile number: {lastEnteredMobile}
          </div>
          {/* {lastEnteredData && (
            <div className='box'>
              {/* <p>Mobile Number: {lastEnteredData.mobileNumber}</p> */}
              {/* Add other fields as needed */}
            {/* </div>  */}
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default Farwardnumber;
